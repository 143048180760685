<template>
    <div class="card card-custom gutter-b col-lg-13 col-xxl-12 mt-5 p-5">
        <div class="d-flex flex-row justify-content-between align-items-baseline">
            <div style="width: 15%">
                <router-link v-if="prevRoute" :to="prevRoute.fullPath" replace>
                    <span class="btn btn-outline-danger btn-md">
                        <span class="svg-icon svg-icon-md svg-icon-light-danger">
                            <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                        </span>
                        {{ $t('CONTROLLERS.BACK') }}
                    </span>
                </router-link>
            </div>
            <div class="d-flex flex-column justify-content-between align-items-center" style="max-width: 60%">
                <h3 class="mb-2">
                    Erreur d'inscription pour la classe :
                </h3>
                <h3 class="text-center mb-2 text-primary">{{ classCustomer.classe.title }}</h3>
                <h5 class="text-muted">Gestionnaire : {{ classCustomer.customer.name }}</h5>
            </div>
            <span></span>
        </div>
        <AssignErrorManagerTable ref="table" :classCustomerId="classCustomerId" />
    </div>
</template>

<script>
import AssignErrorManagerTable from '@/view/content/widgets/manager/AssignErrorManagerTableWidget.vue';
import ApiService from '@/core/services/api.service';

export default {
    components: {
        AssignErrorManagerTable
    },
    data() {
        return {
            classCustomer: {},
            classCustomerId: null,
            prevRoute: null
        };
    },
    mounted() {
        this.classCustomerId = this.$route.params.classesCustomersId;
        if (this.$route.params.classesCustomers) {
            this.classCustomer = {
                ...this.$route.params.classesCustomers,
                classe: { ...this.$route.params.classesCustomers.classe },
                customer: { ...this.$route.params.classesCustomers.customer }
            };
        } else {
            this.getClientsClasses();
        }
    },
    methods: {
        getClientsClasses() {
            ApiService.get('academy/clientclasses', this.classCustomerId).then(result => {
                this.classCustomer = {
                    ...result.data.data[0],
                    classe: { ...result.data.data[0].classe },
                    customer: { ...result.data.data[0].customer }
                };
            });
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from;
        });
    },
    computed: {}
};
</script>
